<template>
    <v-container>
        <!-- Main Hero -->
        <v-col cols="12" class="mt-0 mt-md-16">
            <v-row class="mt-2 mt-md-5">
                <v-col class="text-left col-12 col-md-7">
                    <p class="title-main-hero" v-html="$t('home.title-hero')"></p>
                    <p class="desc-main-hero mt-2">
                        {{ $t("home.hero-desc") }}
                    </p>
                    <p class="coin mt-12">
                        <v-icon color="black" class="mr-2">mdi-hand-coin</v-icon>
                        {{ $t("home.hero-sub-desc") }}
                    </p>

                    <v-btn color="black" class="ml-0 white--text link ma-2 rounded-xxl custom-button p-10"
                        @click="openRegisterModal" v-if="!user">
                        {{ $t("home.create-account") }}
                    </v-btn>

                    <v-btn color="black" class="ml-0 white--text link ma-2 rounded-xxl custom-button p-10"
                        @click="panel()" v-else>
                        ACCEDE AL PANEL
                    </v-btn>
                </v-col>
                <v-col cols="5" class="hidden-sm-and-down hvr-grow-rotate">
                    <img src="https://backend.harmony.fan/storage/illustrations/HARMO.png" class="main-hero" />
                </v-col>
            </v-row>
        </v-col>

        <!-- Second Hero -->
        <v-col cols="12" class="mt-16 mt-md-16">
            <v-row class="mt-10 mt-md-16">
                <v-col class="col-12 col-md-5 mt-md-16 hvr-wobble-horizontal">
                    <img src="https://backend.harmony.fan/storage/illustrations/esfera.png" class="second-hero" />
                </v-col>
                <v-col class="text-right col-12 col-md-7 secod-section">
                    <p class="title-second-hero" v-html="$t('home.title-hero2')" />
                    <p class="desc-second-hero mt-2" v-html="$t('home.hero2-desc')"></p>
                </v-col>
            </v-row>
        </v-col>

        <!-- Third Hero -->
        <v-col cols="12" class="mt-0 mt-md-0">
            <v-row class="mt-0 mt-md-16">
                <v-col class="col-12 col-md-5 mt-md-16 mt-0 hidden-md-and-up hvr-wobble-horizontal">
                    <template v-if="this.lang == 'es'">
                        <img v-show="currentImage === 1" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/social2espanol.png" />
                        <img v-show="currentImage === 2" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/empresa-esp.png" />
                        <img v-show="currentImage === 3" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/social3espanol.png" />
                        <img v-show="currentImage === 4" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/social4espanol.png" />
                        <img v-show="currentImage === 5" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/maria-es.png" />
                    </template>
                    <template v-else>
                        <img v-show="currentImage === 1" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/social2.png" />
                        <img v-show="currentImage === 2" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/empresa.png" />
                        <img v-show="currentImage === 3" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/social3.png" />
                        <img v-show="currentImage === 4" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/social4.png" />
                        <img v-show="currentImage === 5" class="third-hero2"
                            src="https://backend.harmony.fan/storage/illustrations/maria-en.png" />
                    </template>
                </v-col>
                <v-col class="text-left col-12 col-md-7 secod-section2">
                    <p class="title-second-hero" v-html="$t('home.title-hero3')" />
                    <p class="desc-second-hero mt-2" v-html="$t('home.hero3-desc')" />
                </v-col>
                <v-col class="col-12 col-md-5 mt-md-0 hidden-sm-and-down hvr-wobble-horizontal">
                    <template v-if="this.lang == 'es'">
                        <img v-show="currentImage === 1" class="third-hero"
                            src="https://backend.harmony.fan/storage/illustrations/social2espanol.png" />
                        <img v-show="currentImage === 2" class="third-hero"
                            src="https://backend.harmony.fan/storage/illustrations/empresa-esp.png" />
                        <img v-show="currentImage === 3" class="third-hero"
                            src="https://backend.harmony.fan/storage/illustrations/social3espanol.png" />
                        <img v-show="currentImage === 4" class="third-hero"
                            src="https://backend.harmony.fan/storage/illustrations/social4espanol.png" />
                        <img v-show="currentImage === 5" class="third-hero"
                            src="https://backend.harmony.fan/storage/illustrations/maria-es.png" />
                    </template>
                    <template v-else>
                        <img v-show="currentImage === 1" class="third-hero" src="https://backend.harmony.fan/storage/illustrations/social2.png" />
                        <img v-show="currentImage === 2" class="third-hero" src="https://backend.harmony.fan/storage/illustrations/empresa.png" />
                        <img v-show="currentImage === 3" class="third-hero" src="https://backend.harmony.fan/storage/illustrations/social3.png" />
                        <img v-show="currentImage === 4" class="third-hero" src="https://backend.harmony.fan/storage/illustrations/social4.png" />
                        <img v-show="currentImage === 5" class="third-hero"
                            src="https://backend.harmony.fan/storage/illustrations/maria-en.png" />
                    </template>
                </v-col>
            </v-row>
        </v-col>

        <!-- Fourth Hero -->
        <v-col cols="12" class="ml-auto mr-auto mt-16 mt-md-16 py-10 pb-5 hero4-section rounded-xxl px-10">
            <h2 class="title-hero-4">{{ $t("home.join") }}</h2>
            <p class="desc-hero-4 mt-3">{{ $t("home.join-desc") }}</p>

            <v-row align="center" justify="center" class="mt-7 mb-4 sizing">
                <v-col class="col-12 col-sm-6 col-md-3 hvr-float">
                    <v-row class="hero4-card" align="center" justify="center">
                        <img class="bg-image" src="../assets/illustrations/z2.png" alt="" srcset="" />
                        <p class="hero4-card-text" v-html="$t('home.hero4-desc1')" />
                    </v-row>
                </v-col>

                <v-col class="col-12 col-sm-6 col-md-3 hvr-float">
                    <v-row class="hero4-card" align="center" justify="center">
                        <img class="bg-image" src="../assets/illustrations/y2.png" alt="" srcset="" />
                        <p class="hero4-card-text" v-html="$t('home.hero4-desc2')" />
                    </v-row>
                </v-col>

                <v-col class="col-12 col-sm-6 col-md-3 hvr-float">
                    <v-row class="hero4-card" align="center" justify="center">
                        <img class="bg-image" src="../assets/illustrations/x2.png" alt="" srcset="" />
                        <p class="hero4-card-text" v-html="$t('home.hero4-desc3')" />
                    </v-row>
                </v-col>

                <v-col class="col-12 col-sm-6 col-md-3 hvr-float">
                    <v-row class="hero4-card" align="center" justify="center">
                        <img class="bg-image" src="../assets/illustrations/w2.png" alt="" srcset="" />
                        <p class="hero4-card-text" v-html="$t('home.hero4-desc4')" />
                    </v-row>
                </v-col>
            </v-row>
        </v-col>

        <!-- Fifth Hero -->
        <v-col cols="12" class="relative mt-16 mt-md-16 py-10 pb-5 px-10">
            <div class="container-fifth">
                <p class="title-second-hero">
                    <span>{{ $t("home.testimonial-title") }}</span>
                </p>
                <p class="desc-second-hero mt-2 mb-16" v-html="$t('home.testimonial-subtitle')" />
                <div class="controls-fifth">
                    <button @click="prevSlide" class="button-fifth left">&lt;</button>
                    <div class="grid-container-fifth">
                        <div v-for="(card, index) in visibleCards" :key="index" class="card-fifth"
                            :class="{ highlighted: index === 1 }">
                            <div class="avatar-fifth" :style="{ backgroundImage: `url(${getImageSrc(card.img)})` }">
                            </div>
                            <p class="text-fifth">{{ card.description }}</p>
                            <p class="name-fifth">- {{ card.name }}</p>
                        </div>
                    </div>
                    <button @click="nextSlide" class="button-fifth right">&gt;</button>
                </div>
            </div>
        </v-col>

        <!-- Sixth Hero -->
        <v-col cols="12" class="mt-16 mt-md-10">
            <v-row class="mt-10 mt-md-16">
                <v-col class="col-12 col-md-5 mt-md-16 hvr-wobble-horizontal">
                    <img src="https://backend.harmony.fan/storage/illustrations/hero4.png" class="second-hero" />
                </v-col>
                <v-col class="text-right col-12 col-md-7 secod-section">
                    <p class="title-second-hero" v-html="$t('home.title-hero6')" />
                    <p class="desc-second-hero mt-2" v-html="$t('home.hero6-desc')"></p>
                </v-col>
            </v-row>
        </v-col>

        <!-- Seven Hero -->
        <v-col cols="12" class="mt-16 mt-md-0" id="plans-section">
            <v-col class="text-center col-12 secod-section">
                <p class="title-second-hero">
                    <span>{{ $t("home.plan") }}</span>
                </p>
                <p class="desc-second-hero mt-2" v-html="$t('home.plan-desc')" />

                <v-row align="center" justify="center">
                    <div class="mt-16 gradient">
                        <v-btn small class="mr-2 rounded-lg" :text="planCondition(1)" @click="changePlan(1)">
                            {{ $t("home.plan-monthly") }}
                        </v-btn>
                        <v-btn small class="rounded-lg" :text="planCondition(2)" @click="changePlan(2)">
                            {{ $t("home.plan-annual") }}
                        </v-btn>
                    </div>
                </v-row>

                <v-row align="center" justify="center" class="mt-10">
                    <div class="mt-16 plan-card text-center rounded-lg">
                        <p>{{ $t("home.title-hero7") }}</p>
                        <v-row align="center" justify="center" class="mt-4">
                            <p class="plan-number-dash" v-show="plan == 1">$15.99</p>

                            <transition name="fadeInUp">
                                <p class="plan-number-dash" v-show="plan == 2">$149.90</p>
                            </transition>
                            <p class="plan-number">$0</p>
                        </v-row>

                        <v-btn text color="black"
                            class="ml-0 white--text link ma-2 rounded-xl custom-button2 p-10 my-5 mt-2">
                            {{ $t("home.select-plan") }}
                        </v-btn>

                        <v-col class="mt-5 px-10">
                            <v-row align="center" justify="space-between">
                                <v-row>
                                    <v-icon color="black" class="mr-4"> mdi-link </v-icon>
                                    <p class="mb-0 plan-feature">
                                        {{ $t("home.plan-feature1") }}
                                    </p>
                                </v-row>
                                <v-icon color="#8e84c0" class="ml-2"> mdi-check-circle </v-icon>
                            </v-row>

                            <div class="mt-6">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4">
                                            mdi-palette-outline
                                        </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature2") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>

                            <div class="mt-6">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4">
                                            mdi-multimedia
                                        </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature8") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>

                            <div class="mt-6">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4"> mdi-finance </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature3") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>

                            <div class="mt-6">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4">
                                            mdi-advertisements-off
                                        </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature6") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>

                            <div class="mt-6">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4">
                                            mdi-currency-usd
                                        </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature4") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>

                            <div class="mt-6">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4"> mdi-hand-coin </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature5") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>

                            <div class="mt-6 mb-10">
                                <v-row align="center" justify="space-between">
                                    <v-row>
                                        <v-icon color="black" class="mr-4"> mdi-clock-fast </v-icon>
                                        <p class="mb-0 plan-feature">
                                            {{ $t("home.plan-feature7") }}
                                        </p>
                                    </v-row>
                                    <v-icon color="#8e84c0" class="ml-2">
                                        mdi-check-circle
                                    </v-icon>
                                </v-row>
                            </div>
                        </v-col>
                    </div>
                </v-row>
            </v-col>
        </v-col>

        <!-- Eighth Section -->
        <v-btn color="black" class="ml-0 white--text link ma-2 rounded-xxl custom-button p-10 mt-16"
            @click="openRegisterModal" v-if="!user">
            {{ $t("home.register-start") }}
        </v-btn>

        <v-btn color="black" class="ml-0 white--text link ma-2 rounded-xxl custom-button p-10 mt-16" @click="panel()"
            v-else>
            MI CUENTA
        </v-btn>

        <v-col cols="12" class="mt-0" id="plans-section">
            <v-col class="text-center col-12 secod-section8">
                <p class="title-second-hero">
                    <span>{{ $t("home.join-title") }}</span>
                </p>
                <p class="desc-hero-8 mt-2">{{ $t("home.join-descr") }}</p>
            </v-col>
        </v-col>
    </v-container>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            plan: 1,
            currentImage: 1,
            interval: null,
            user: null,
            lang: "es",
            startIndex: 0,
            cards: [],
            loadingStates: {},
        };
    },

    computed: {
        visibleCards() {
            var cols = this.$vuetify.breakpoint.width <= 959 ? 1 : 3;
            return [
                this.cards[this.startIndex],
                this.cards[(this.startIndex + 1) % this.cards.length],
                this.cards[(this.startIndex + 2) % this.cards.length],
            ].slice(0, cols);
        },
    },

    created() {
        this.checkLanguage();
        this.checkUser();
        this.$root.$on("languageChanged", this.handleLanguageChange);
        this.$root.$on("userUpdated", this.handleUserUpdate);
        this.updateCards();
        this.preloadImages();
    },

    mounted() {
        this.interval = setInterval(this.changeImage, 2000);
    },

    beforeDestroy() {
        clearInterval(this.interval);
        this.$root.$off("languageChanged", this.handleLanguageChange);
        this.$root.$off("userUpdated", this.handleUserUpdate);
    },

    methods: {
        panel() {
            this.$root.$emit("openPanel");
        },

        handleUserUpdate(newUser) {
            this.user = newUser;
        },

        planCondition(value) {
            return this.plan !== value;
        },

        changePlan(value) {
            this.plan = value;
        },

        changeImage() {
            this.currentImage = (this.currentImage % 5) + 1;
        },

        checkLanguage() {
            const storedLocale = localStorage.getItem("locale");
            this.lang = storedLocale;
        },

        checkUser() {
            const storedLocale = localStorage.getItem("user");
            this.user = storedLocale;
        },

        handleLanguageChange(newLang) {
            this.lang = newLang;
        },

        openRegisterModal() {
            this.$root.$emit("openRegisterModal");
        },

        prevSlide() {
            this.startIndex =
                (this.startIndex - 1 + this.cards.length) % this.cards.length;
        },

        nextSlide() {
            this.startIndex = (this.startIndex + 1) % this.cards.length;
        },

        preloadImages() {
            this.cards.forEach((card) => {
                const img = new Image();
                this.$set(this.loadingStates, card.img, true);
                img.src = require(`@/assets/illustrations/${card.img}`);
                img.onload = () => {
                    this.$set(this.loadingStates, card.img, false);
                };
            });
        },

        getImageSrc(imgName) {
            return require(`@/assets/illustrations/${imgName}`);
        },

        handleImageLoad(imgName) {
            this.$set(this.loadingStates, imgName, false);
        },

        updateCards() {
            this.cards = [
                { img: "a.jpg", name: this.$t("home.testimonial-name1"), description: this.$t("home.testimonial-desc1") },
                { img: "h.jpg", name: this.$t("home.testimonial-name2"), description: this.$t("home.testimonial-desc2") },
                { img: "e.jpg", name: this.$t("home.testimonial-name3"), description: this.$t("home.testimonial-desc3") },
                { img: "d.jpg", name: this.$t("home.testimonial-name4"), description: this.$t("home.testimonial-desc4") },
                { img: "f.jpg", name: this.$t("home.testimonial-name5"), description: this.$t("home.testimonial-desc5") },
                { img: "b.jpg", name: this.$t("home.testimonial-name6"), description: this.$t("home.testimonial-desc6") },
                { img: "c.jpg", name: this.$t("home.testimonial-name7"), description: this.$t("home.testimonial-desc7") },
                { img: "g.jpg", name: this.$t("home.testimonial-name8"), description: this.$t("home.testimonial-desc8") },
                { img: "i.png", name: this.$t("home.testimonial-name9"), description: this.$t("home.testimonial-desc9") },
                { img: "j.jpg", name: this.$t("home.testimonial-name10"), description: this.$t("home.testimonial-desc10") }
            ];
            this.startIndex = 0;
            this.preloadImages();
        },
    },

    watch: {
        "$i18n.locale": "updateCards",
        "$vuetify.breakpoint.width": function (newWidth) {
            this.visibleCount = newWidth <= 959 ? 1 : 3;
            this.startIndex = 0;
        },
    },
};
</script>

<style scoped>
@import "~hover.css/css/hover-min.css";

.plan-feature {
    color: black;
    font-weight: 400;
}

.plan-number {
    font-size: 40px;
    margin-bottom: 34px;
    margin-left: 8px;
    font-weight: 800;
}

.plan-number-dash {
    font-weight: 800;
    font-size: 22px;
    text-decoration: line-through;
    text-decoration-color: #ff0b0b;
}

.plan-card {
    width: 22rem;
    padding-top: 38px;
    padding-bottom: 21px;
    background-color: #e1e1e1;
}

.gradient {
    width: 185px;
    padding: 8px;
    border-radius: 9px;
    background: #8e84c0;
    background: -moz-linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
    background: -webkit-linear-gradient(90deg,
            #8e84c0 0%,
            rgb(236, 196, 255) 100%);
    background: linear-gradient(90deg, #8e84c0 0%, rgb(236, 196, 255) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#8e84c0", endColorstr="rgb(236, 196, 255)", GradientType=1);
}

.hero4-card-text {
    color: white;
    font-size: 14px;
    position: relative;
    margin: auto;
}

.hero4-card {
    background: radial-gradient(circle at 100% 100%,
            #f990ff 0,
            #f990ff 9px,
            transparent 9px) 0% 0%/12px 12px no-repeat,
        radial-gradient(circle at 0 100%, #f990ff 0, #f990ff 9px, transparent 9px) 100% 0%/12px 12px no-repeat,
        radial-gradient(circle at 100% 0, #f990ff 0, #f990ff 9px, transparent 9px) 0% 100%/12px 12px no-repeat,
        radial-gradient(circle at 0 0, #f990ff 0, #f990ff 9px, transparent 9px) 100% 100%/12px 12px no-repeat,
        linear-gradient(#f990ff, #f990ff) 50% 50% / calc(100% - 6px) calc(100% - 24px) no-repeat,
        linear-gradient(#f990ff, #f990ff) 50% 50% / calc(100% - 24px) calc(100% - 6px) no-repeat,
        linear-gradient(#ff6ef9 0%, #40a0e7 100%);

    -webkit-box-shadow: 0px 3px 11px 1px rgba(106, 37, 196, 1);
    -moz-box-shadow: 0px 3px 11px 1px rgba(106, 37, 196, 1);
    box-shadow: 0px 3px 11px 1px rgba(106, 37, 196, 1);

    border-radius: 20px;
    padding: 7px;
    box-sizing: border-box;
    width: 100%;
    height: 10rem;
    position: relative;
    max-width: 225px;
    margin: auto;
}

.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.sizing {
    max-width: auto;
    margin: auto;
}

@media (max-width: 959px) {
    .sizing {
        max-width: 520px;
    }
}

.title-hero-4 {
    font-size: 28px;
    color: white;
}

.desc-hero-4 {
    color: white;
}

.hero4-section {
    position: relative;
    animation: animatedgradient 6s ease alternate infinite;
    background: linear-gradient(60deg,
            rgb(151, 138, 206) 0%,
            rgb(219, 175, 240) 20%,
            rgb(151, 138, 206) 40%,
            rgb(219, 175, 240) 60%,
            rgb(151, 138, 206) 80%,
            rgb(219, 175, 240) 100%);
    background-size: 300% 300%;
    overflow: hidden;
    border-left-style: solid;
    border-left-color: white;
    border-left-width: 25px;
    margin-right: 12rem !important;
    margin-left: 0rem;
}

.hero4-section::before {
    content: "";
    position: absolute;
    top: -41px;
    left: -54px;
    width: 139px;
    height: 139px;
    background: white;
    border-radius: 50%;
}

.third-hero {
    margin-left: -41px;
    margin-right: -235px;
    width: 126%;
    margin-right: 1rem;
    /*position: absolute;*/
}

.third-hero2 {
    margin-left: -68px;
    margin-right: -235px;
    width: 120%;
    margin-right: -3rem;
    /*position: absolute;*/
}

.secod-section {
    margin-top: 11rem;
}

.secod-section2 {
    margin-top: 13rem;
}

.secod-section8 {
    margin-top: 4rem;
    margin-bottom: 7rem;
}

.desc-hero-8 {
    font-size: 26px;
    color: #595959 !important;
}

.second-hero {
    margin-top: -2rem;
    /*max-width: 38rem;*/
    width: 136%;
    margin-right: 1rem;
}

.title-second-hero {
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 34px;
    line-height: 1;
    color: black;
}

.desc-second-hero {
    font-size: 21px;
    color: #595959 !important;
}

.title-second-hero span {
    display: block;
    font-size: 48px;
    font-weight: 800;
}

.main-hero {
    /* max-width: 29rem;*/
    width: 103%;
    margin-top: -51px;
}

.title-main-hero {
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 30px;
    line-height: 1.2;
    color: black;
}

.title-main-hero span {
    display: block;
    font-size: 53px;
    font-weight: 800;
}

.desc-main-hero {
    font-size: 1.35rem;
    color: #595959 !important;
}

.coin {
    color: black;
    font-size: 19px;
    font-weight: 500;
}

.custom-button {
    animation: animatedgradient 3s ease alternate infinite;
    background: linear-gradient(60deg,
            #8e84c0 0%,
            rgb(236, 196, 255) 20%,
            #8e84c0 40%,
            rgb(236, 196, 255) 60%,
            #8e84c0 80%,
            rgb(236, 196, 255) 100%);
    background-size: 300% 300%;
    border: 0px;
    border-radius: 25px;
    padding: 28px !important;
}

.custom-button2 {
    background: linear-gradient(60deg,
            #8e84c0 0%,
            rgb(236, 196, 255) 20%,
            #8e84c0 40%,
            rgb(236, 196, 255) 60%,
            #8e84c0 80%,
            rgb(236, 196, 255) 100%);
    background-size: 300% 300%;
    border: 0px;
    border-radius: 25px;
    padding: 12px !important;
}

.container-fifth {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.controls-fifth {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
    height: 100%;
}

.button-fifth {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 3rem;
    font-weight: 700;
    padding: 0 0.5rem;
    color: #ffffff;
    text-shadow: -1px 2px 2px rgb(168, 168, 168);
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.left {
    left: 0%;
}

.right {
    right: 0%;
}

.button-fifth:hover {
    background-color: var(--accent);
    color: var(--accent-foreground);
}

.grid-container-fifth {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.card-fifth {
    border-radius: 25px;
    background-color: #f3f4f6;
    text-align: center;
    max-width: 300px;
    padding: 3rem 1rem;
}

.card-fifth.highlighted {
    background-color: #8a72c1;
    color: white;
}

.highlighted .avatar-fifth {
    background-color: #8a72c1;
    color: white;
    width: 14rem;
    height: 14rem;
}

.avatar-fifth {
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
    border-radius: 50%;
    object-fit: cover;
    transition: 0.5s;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

.text-fifth {
    margin-top: 1rem;
}

.name-fifth {
    margin-top: 0.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1227px) {
    .third-hero {
        width: 110%;
    }
}

@media (max-width: 959px) {
    .grid-container-fifth {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }

    .button-fifth {
        padding: 0;
    }

    .card-fifth {
        background-color: #8a72c1;
        color: white;
    }

    .second-hero {
        width: 90%;
    }

    .secod-section {
        margin-top: 5rem;
    }

    .secod-section2 {
        margin-top: 1rem;
    }

    .third-hero {
        width: 103%;
    }

    .third-hero2 {
        width: 103%;
        margin-top: -60px;
        margin-bottom: -48px;
    }

    .hero4-section {
        margin-left: -1rem;
        margin-top: 9rem !important;
    }

    .hero4-section::before {
        top: -21px;
        left: -64px;
        width: 109px;
        height: 109px;
    }
}
</style>